<template>
  <MessagingReviewTextComponent />
</template>

<script>
import MessagingReviewTextComponent from "../components/MessagingReviewTextComponent.vue";

export default {
  name: "MessagingReviewText",
  components: {
    MessagingReviewTextComponent,
  },
};
</script>
