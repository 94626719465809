import { render } from "./MessagingReviewText.vue?vue&type=template&id=e6242002"
import script from "./MessagingReviewText.vue?vue&type=script&lang=js"
export * from "./MessagingReviewText.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e6242002"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e6242002', script)) {
    api.reload('e6242002', script)
  }
  
  module.hot.accept("./MessagingReviewText.vue?vue&type=template&id=e6242002", () => {
    api.rerender('e6242002', render)
  })

}

script.__file = "src/views/MessagingReviewText.vue"

export default script